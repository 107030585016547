import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Image from 'gatsby-image'

export default ({ data, pageContext }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext

  return (
    <Layout>
      <header className="post">
        <h1>
          <Link to="/">Seinpost</Link>
        </h1>
      </header>
      <main>
        <div className="text">
          <h1>{post.frontmatter.title}</h1>
          <time dateTime={post.frontmatter.date}>
            {post.frontmatter.dutchDate}
          </time>
          <div
            className="post"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
        {data.allFile.edges.map(({ node }) => (
          <Image
            className={
              node.childImageSharp.fluid.aspectRatio > 1
                ? `landscape`
                : `portrait`
            }
            key={node.id}
            fluid={{
              ...node.childImageSharp.fluid,
              sizes:
                node.childImageSharp.fluid.aspectRatio > 1
                  ? `(max-width: 1080px) calc(100vw-24px), 1080px` // Sizes for landscape images
                  : `(max-width: 640px) calc(100vw-24px), (max-width: 1080px) calc(50vw-18px), 534px`, // Sizes for portrait images
            }}
            placeholderClassName="placeholder"
          />
        ))}
        <footer>
          <nav>
            {previous ? (
              <Link to={previous.fields.slug}>
                <span>Recenter</span>
                <h3>{previous.frontmatter.title}</h3>
              </Link>
            ) : (
              <Link to="/">
                <span>Overzicht</span>
              </Link>
            )}
            {next ? (
              <Link to={next.fields.slug}>
                <span>Ouder</span>
                <h3>{next.frontmatter.title}</h3>
              </Link>
            ) : (
              <Link to="/">
                <span>Overzicht</span>
              </Link>
            )}
          </nav>
        </footer>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $dirName: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        dutchDate: date(formatString: "D MMMM YYYY", locale: "nl")
        date
      }
    }
    allFile(
      filter: {
        relativeDirectory: { eq: $dirName }
        extension: { regex: "/(jpeg|jpg)/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1080, quality: 90) {
              aspectRatio
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
